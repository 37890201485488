// @flow
import { doToast } from 'redux/actions/notifications';

export function doFailedSignatureToast(dispatch: Dispatch, channelName: string) {
  dispatch(
    doToast({
      message: __('Unable to verify signature for %channel%.', { channel: channelName }),
      isError: true,
    })
  );
}

export function devToast(dispatch: Dispatch, msg: string) {
}
