var $jscomp = $jscomp || {};
$jscomp.scope = {};
$jscomp.owns = function (a, c) {
  return Object.prototype.hasOwnProperty.call(a, c);
};
$jscomp.ASSUME_ES5 = !1;
$jscomp.ASSUME_NO_NATIVE_MAP = !1;
$jscomp.ASSUME_NO_NATIVE_SET = !1;
$jscomp.defineProperty =
  $jscomp.ASSUME_ES5 || 'function' == typeof Object.defineProperties
    ? Object.defineProperty
    : function (a, c, e) {
        a != Array.prototype && a != Object.prototype && (a[c] = e.value);
      };
$jscomp.getGlobal = function (a) {
  return 'undefined' != typeof window && window === a ? a : 'undefined' != typeof global && null != global ? global : a;
};
$jscomp.global = $jscomp.getGlobal(this);
$jscomp.polyfill = function (a, c, e, f) {
  if (c) {
    e = $jscomp.global;
    a = a.split('.');
    for (f = 0; f < a.length - 1; f++) {
      var b = a[f];
      b in e || (e[b] = {});
      e = e[b];
    }
    a = a[a.length - 1];
    f = e[a];
    c = c(f);
    c != f && null != c && $jscomp.defineProperty(e, a, { configurable: !0, writable: !0, value: c });
  }
};
$jscomp.polyfill(
  'Object.assign',
  function (a) {
    return a
      ? a
      : function (a, e) {
          for (var c = 1; c < arguments.length; c++) {
            var b = arguments[c];
            if (b) for (var g in b) $jscomp.owns(b, g) && (a[g] = b[g]);
          }
          return a;
        };
  },
  'es6',
  'es3'
);
$jscomp.SYMBOL_PREFIX = 'jscomp_symbol_';
$jscomp.initSymbol = function () {
  $jscomp.initSymbol = function () {};
  $jscomp.global.Symbol || ($jscomp.global.Symbol = $jscomp.Symbol);
};
$jscomp.Symbol = (function () {
  var a = 0;
  return function (c) {
    return $jscomp.SYMBOL_PREFIX + (c || '') + a++;
  };
})();
$jscomp.initSymbolIterator = function () {
  $jscomp.initSymbol();
  var a = $jscomp.global.Symbol.iterator;
  a || (a = $jscomp.global.Symbol.iterator = $jscomp.global.Symbol('iterator'));
  'function' != typeof Array.prototype[a] &&
    $jscomp.defineProperty(Array.prototype, a, {
      configurable: !0,
      writable: !0,
      value: function () {
        return $jscomp.arrayIterator(this);
      },
    });
  $jscomp.initSymbolIterator = function () {};
};
$jscomp.arrayIterator = function (a) {
  var c = 0;
  return $jscomp.iteratorPrototype(function () {
    return c < a.length ? { done: !1, value: a[c++] } : { done: !0 };
  });
};
$jscomp.iteratorPrototype = function (a) {
  $jscomp.initSymbolIterator();
  a = { next: a };
  a[$jscomp.global.Symbol.iterator] = function () {
    return this;
  };
  return a;
};
$jscomp.makeIterator = function (a) {
  $jscomp.initSymbolIterator();
  var c = a[Symbol.iterator];
  return c ? c.call(a) : $jscomp.arrayIterator(a);
};
$jscomp.FORCE_POLYFILL_PROMISE = !1;
$jscomp.polyfill(
  'Promise',
  function (a) {
    function c() {
      this.batch_ = null;
    }
    function e(d) {
      return d instanceof b
        ? d
        : new b(function (a, b) {
            a(d);
          });
    }
    if (a && !$jscomp.FORCE_POLYFILL_PROMISE) return a;
    c.prototype.asyncExecute = function (d) {
      null == this.batch_ && ((this.batch_ = []), this.asyncExecuteBatch_());
      this.batch_.push(d);
      return this;
    };
    c.prototype.asyncExecuteBatch_ = function () {
      var d = this;
      this.asyncExecuteFunction(function () {
        d.executeBatch_();
      });
    };
    var f = $jscomp.global.setTimeout;
    c.prototype.asyncExecuteFunction = function (d) {
      f(d, 0);
    };
    c.prototype.executeBatch_ = function () {
      for (; this.batch_ && this.batch_.length; ) {
        var d = this.batch_;
        this.batch_ = [];
        for (var a = 0; a < d.length; ++a) {
          var b = d[a];
          delete d[a];
          try {
            b();
          } catch (h) {
            this.asyncThrow_(h);
          }
        }
      }
      this.batch_ = null;
    };
    c.prototype.asyncThrow_ = function (d) {
      this.asyncExecuteFunction(function () {
        throw d;
      });
    };
    var b = function (d) {
      this.state_ = 0;
      this.result_ = void 0;
      this.onSettledCallbacks_ = [];
      var a = this.createResolveAndReject_();
      try {
        d(a.resolve, a.reject);
      } catch (l) {
        a.reject(l);
      }
    };
    b.prototype.createResolveAndReject_ = function () {
      function a(a) {
        return function (d) {
          c || ((c = !0), a.call(b, d));
        };
      }
      var b = this,
        c = !1;
      return { resolve: a(this.resolveTo_), reject: a(this.reject_) };
    };
    b.prototype.resolveTo_ = function (a) {
      if (a === this) this.reject_(new TypeError('A Promise cannot resolve to itself'));
      else if (a instanceof b) this.settleSameAsPromise_(a);
      else {
        a: switch (typeof a) {
          case 'object':
            var d = null != a;
            break a;
          case 'function':
            d = !0;
            break a;
          default:
            d = !1;
        }
        d ? this.resolveToNonPromiseObj_(a) : this.fulfill_(a);
      }
    };
    b.prototype.resolveToNonPromiseObj_ = function (a) {
      var b = void 0;
      try {
        b = a.then;
      } catch (l) {
        this.reject_(l);
        return;
      }
      'function' == typeof b ? this.settleSameAsThenable_(b, a) : this.fulfill_(a);
    };
    b.prototype.reject_ = function (a) {
      this.settle_(2, a);
    };
    b.prototype.fulfill_ = function (a) {
      this.settle_(1, a);
    };
    b.prototype.settle_ = function (a, b) {
      if (0 != this.state_)
        throw Error(('Cannot settle(' + a + ', ' + b) | ('): Promise already settled in state' + this.state_));
      this.state_ = a;
      this.result_ = b;
      this.executeOnSettledCallbacks_();
    };
    b.prototype.executeOnSettledCallbacks_ = function () {
      if (null != this.onSettledCallbacks_) {
        for (var a = this.onSettledCallbacks_, b = 0; b < a.length; ++b) a[b].call(), (a[b] = null);
        this.onSettledCallbacks_ = null;
      }
    };
    var g = new c();
    b.prototype.settleSameAsPromise_ = function (a) {
      var b = this.createResolveAndReject_();
      a.callWhenSettled_(b.resolve, b.reject);
    };
    b.prototype.settleSameAsThenable_ = function (a, b) {
      var c = this.createResolveAndReject_();
      try {
        a.call(b, c.resolve, c.reject);
      } catch (h) {
        c.reject(h);
      }
    };
    b.prototype.then = function (a, c) {
      function d(a, b) {
        return 'function' == typeof a
          ? function (b) {
              try {
                h(a(b));
              } catch (m) {
                e(m);
              }
            }
          : b;
      }
      var h,
        e,
        g = new b(function (a, b) {
          h = a;
          e = b;
        });
      this.callWhenSettled_(d(a, h), d(c, e));
      return g;
    };
    b.prototype.catch = function (a) {
      return this.then(void 0, a);
    };
    b.prototype.callWhenSettled_ = function (a, b) {
      function c() {
        switch (d.state_) {
          case 1:
            a(d.result_);
            break;
          case 2:
            b(d.result_);
            break;
          default:
            throw Error('Unexpected state: ' + d.state_);
        }
      }
      var d = this;
      null == this.onSettledCallbacks_
        ? g.asyncExecute(c)
        : this.onSettledCallbacks_.push(function () {
            g.asyncExecute(c);
          });
    };
    b.resolve = e;
    b.reject = function (a) {
      return new b(function (b, c) {
        c(a);
      });
    };
    b.race = function (a) {
      return new b(function (b, c) {
        for (var d = $jscomp.makeIterator(a), g = d.next(); !g.done; g = d.next()) e(g.value).callWhenSettled_(b, c);
      });
    };
    b.all = function (a) {
      var c = $jscomp.makeIterator(a),
        d = c.next();
      return d.done
        ? e([])
        : new b(function (a, b) {
            function g(b) {
              return function (c) {
                f[b] = c;
                h--;
                0 == h && a(f);
              };
            }
            var f = [],
              h = 0;
            do f.push(void 0), h++, e(d.value).callWhenSettled_(g(f.length - 1), b), (d = c.next());
            while (!d.done);
          });
    };
    return b;
  },
  'es6',
  'es3'
);
(function (a, c) {
  'object' === typeof exports && 'undefined' !== typeof module
    ? (module.exports = c())
    : 'function' === typeof define && define.amd
    ? define(c)
    : (a.canAutoplay = c());
})(this, function () {
  function a(a) {
    return Object.assign({ muted: !1, timeout: 250, inline: !1 }, a);
  }
  function c(a, c) {
    var b = a.muted,
      e = a.timeout;
    a = a.inline;
    c = c();
    var f = c.element;
    c = c.source;
    var h = void 0,
      g = void 0,
      k = void 0;
    f.muted = b;
    !0 === b && f.setAttribute('muted', 'muted');
    !0 === a && f.setAttribute('playsinline', 'playsinline');
    f.src = c;
    return new Promise(function (a) {
      h = f.play();
      g = setTimeout(function () {
        k(!1, Error('Timeout ' + e + ' ms has been reached'));
      }, e);
      k = function (b) {
        var c = 1 < arguments.length && void 0 !== arguments[1] ? arguments[1] : null;
        clearTimeout(g);
        a({ result: b, error: c });
      };
      void 0 !== h
        ? h
            .then(function () {
              return k(!0);
            })
            .catch(function (a) {
              return k(!1, a);
            })
        : k(!0);
    });
  }
  var e = new Blob(
      [
        new Uint8Array([
          255,
          227,
          24,
          196,
          0,
          0,
          0,
          3,
          72,
          1,
          64,
          0,
          0,
          4,
          132,
          16,
          31,
          227,
          192,
          225,
          76,
          255,
          67,
          12,
          255,
          221,
          27,
          255,
          228,
          97,
          73,
          63,
          255,
          195,
          131,
          69,
          192,
          232,
          223,
          255,
          255,
          207,
          102,
          239,
          255,
          255,
          255,
          101,
          158,
          206,
          70,
          20,
          59,
          255,
          254,
          95,
          70,
          149,
          66,
          4,
          16,
          128,
          0,
          2,
          2,
          32,
          240,
          138,
          255,
          36,
          106,
          183,
          255,
          227,
          24,
          196,
          59,
          11,
          34,
          62,
          80,
          49,
          135,
          40,
          0,
          253,
          29,
          191,
          209,
          200,
          141,
          71,
          7,
          255,
          252,
          152,
          74,
          15,
          130,
          33,
          185,
          6,
          63,
          255,
          252,
          195,
          70,
          203,
          86,
          53,
          15,
          255,
          255,
          247,
          103,
          76,
          121,
          64,
          32,
          47,
          255,
          34,
          227,
          194,
          209,
          138,
          76,
          65,
          77,
          69,
          51,
          46,
          57,
          55,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          255,
          227,
          24,
          196,
          73,
          13,
          153,
          210,
          100,
          81,
          135,
          56,
          0,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
          170,
        ]),
      ],
      { type: 'audio/mpeg' }
    ),
    f = new Blob(
      [
        new Uint8Array([
          0,
          0,
          0,
          28,
          102,
          116,
          121,
          112,
          105,
          115,
          111,
          109,
          0,
          0,
          2,
          0,
          105,
          115,
          111,
          109,
          105,
          115,
          111,
          50,
          109,
          112,
          52,
          49,
          0,
          0,
          0,
          8,
          102,
          114,
          101,
          101,
          0,
          0,
          2,
          239,
          109,
          100,
          97,
          116,
          33,
          16,
          5,
          32,
          164,
          27,
          255,
          192,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          55,
          167,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          112,
          33,
          16,
          5,
          32,
          164,
          27,
          255,
          192,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          55,
          167,
          128,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          112,
          0,
          0,
          2,
          194,
          109,
          111,
          111,
          118,
          0,
          0,
          0,
          108,
          109,
          118,
          104,
          100,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          3,
          232,
          0,
          0,
          0,
          47,
          0,
          1,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          64,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          3,
          0,
          0,
          1,
          236,
          116,
          114,
          97,
          107,
          0,
          0,
          0,
          92,
          116,
          107,
          104,
          100,
          0,
          0,
          0,
          3,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          2,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          47,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          1,
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          64,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          36,
          101,
          100,
          116,
          115,
          0,
          0,
          0,
          28,
          101,
          108,
          115,
          116,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          47,
          0,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          1,
          100,
          109,
          100,
          105,
          97,
          0,
          0,
          0,
          32,
          109,
          100,
          104,
          100,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          172,
          68,
          0,
          0,
          8,
          0,
          85,
          196,
          0,
          0,
          0,
          0,
          0,
          45,
          104,
          100,
          108,
          114,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          115,
          111,
          117,
          110,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          83,
          111,
          117,
          110,
          100,
          72,
          97,
          110,
          100,
          108,
          101,
          114,
          0,
          0,
          0,
          1,
          15,
          109,
          105,
          110,
          102,
          0,
          0,
          0,
          16,
          115,
          109,
          104,
          100,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          36,
          100,
          105,
          110,
          102,
          0,
          0,
          0,
          28,
          100,
          114,
          101,
          102,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          12,
          117,
          114,
          108,
          32,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          211,
          115,
          116,
          98,
          108,
          0,
          0,
          0,
          103,
          115,
          116,
          115,
          100,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          87,
          109,
          112,
          52,
          97,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          2,
          0,
          16,
          0,
          0,
          0,
          0,
          172,
          68,
          0,
          0,
          0,
          0,
          0,
          51,
          101,
          115,
          100,
          115,
          0,
          0,
          0,
          0,
          3,
          128,
          128,
          128,
          34,
          0,
          2,
          0,
          4,
          128,
          128,
          128,
          20,
          64,
          21,
          0,
          0,
          0,
          0,
          1,
          244,
          0,
          0,
          1,
          243,
          249,
          5,
          128,
          128,
          128,
          2,
          18,
          16,
          6,
          128,
          128,
          128,
          1,
          2,
          0,
          0,
          0,
          24,
          115,
          116,
          116,
          115,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          2,
          0,
          0,
          4,
          0,
          0,
          0,
          0,
          28,
          115,
          116,
          115,
          99,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          2,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          28,
          115,
          116,
          115,
          122,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          2,
          0,
          0,
          1,
          115,
          0,
          0,
          1,
          116,
          0,
          0,
          0,
          20,
          115,
          116,
          99,
          111,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          44,
          0,
          0,
          0,
          98,
          117,
          100,
          116,
          97,
          0,
          0,
          0,
          90,
          109,
          101,
          116,
          97,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          33,
          104,
          100,
          108,
          114,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          109,
          100,
          105,
          114,
          97,
          112,
          112,
          108,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          45,
          105,
          108,
          115,
          116,
          0,
          0,
          0,
          37,
          169,
          116,
          111,
          111,
          0,
          0,
          0,
          29,
          100,
          97,
          116,
          97,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          76,
          97,
          118,
          102,
          53,
          54,
          46,
          52,
          48,
          46,
          49,
          48,
          49,
        ]),
      ],
      { type: 'video/mp4' }
    );
  return {
    audio: function (b) {
      b = a(b);
      return c(b, function () {
        return { element: document.createElement('audio'), source: URL.createObjectURL(e) };
      });
    },
    video: function (b) {
      b = a(b);
      return c(b, function () {
        return { element: document.createElement('video'), source: URL.createObjectURL(f) };
      });
    },
  };
});
